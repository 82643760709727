import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private httpClient: HttpClient) {}

  // isLoggedIn = sessionStorage.getItem("token") ? true : false;

  loginUser(data) {
    return this.httpClient.post(environment.url + "/user/login_user", data);
  }

  loginUserDetail(data) {
    return this.httpClient.post(
      environment.url + "/user/get_login_user_detail",
      data
    );
  }

  addLoginLog(loginData) {
    return this.httpClient.post(
      environment.url + "/loginLog/add_login_log",
      loginData
    );
  }

  getSessionId(sessionId){
    return this.httpClient.post(environment.url + "/loginLog/get_session_id", sessionId)
  }

  addLogoutLog(logoutData) {
    return this.httpClient.post(
      environment.url + "/loginLog/add_logout_log",
      logoutData
    );
  }
}
