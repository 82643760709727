import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  constructor(private datepipe: DatePipe) {}

  date;

  tableIndex(data) {
    for (let i = 0; i < data.length; i++) {
      data[i]["index"] = i + 1;
    }
    return data;
  }

  getDate() {
    this.date = new Date();
    return this.datepipe.transform(this.date, "yyyy-MM-dd");
  }

  getUniqueDate() {
    this.date = new Date();
    return this.datepipe.transform(this.date, "E dd MMMM YYYY");
  }

  getTime() {
    let date = new Date();
    var time = date.getHours() + "." + date.getMinutes() + "." + date.getSeconds();
    return moment(time, ["HH.mm.ss"]).format("hh:mm:ss A");
  } 
}
