import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// Import Containers
import { DefaultLayoutComponent } from "./containers";
import { CanActivateDashboardService } from "./Guard/can-activate-dashboard.service";
import { CanLoadLoginService } from "./Guard/can-load-login.service";
// Error Components
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [CanActivateDashboardService],
      },
    ],
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./views/auth/auth.module").then((m) => m.AuthModule),
      canLoad: [CanLoadLoginService]
  },
  {
    path: "attendance",
    loadChildren: () =>
      import("./views/attendance/attendance.module").then(
        (m) => m.AttendanceModule
      ),      
  },
  {
    path: "**",
    component: P404Component,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: "legacy",
      initialNavigation: "enabled",
      scrollPositionRestoration: "top",      
    }),    
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
