import { INavData } from "@coreui/angular";
import { environment } from "../environments/environment";

export const navItems: INavData[] = [
  {
    name: "Dashboard",
    url: "/dashboard/mainDashboard",
    icon: "cil-gauge",
    // role: [environment.role_admin, environment.role_user],
  },
  {
    name: "Master",
    url: "/dashboard/master",
    icon: "cil-equalizer",
    // role: [environment.role_admin, environment.role_user],
  },
  {
    name: "Projects",
    url: "/dashboard/projects",
    icon: "cil-screen-desktop",
    // role: [environment.role_admin, environment.role_user],
  },
  {
    name: "Clients",
    url: "/dashboard/clients",
    icon: "cil-user",
    // role: [environment.role_admin],
  },
  {
    name: "Staff",
    url: "/dashboard/staff",
    icon: "cil-people",
    // role: [environment.role_admin, environment.role_user],
  },
  {
    name: "Attendance",
    url: "/dashboard/attendance",
    icon: "cil-chart",
    // role: [environment.role_admin, environment.role_user],
  },
  {
    name: "Accounts",
    url: "/dashboard/accounts",
    icon: "cil-wallet",
    // role: [environment.role_admin],
  },
  {
    name: "Leave",
    url: "/dashboard/leave",
    icon: "icon-pie-chart",
    // role: [environment.role_admin, environment.role_user],
  },
  {
    name: "Inquiries",
    url: "/dashboard/inquiries",
    icon: "cil-envelope-letter",
    // role: [environment.role_admin],
  },
  {
    name: "Blogs",
    url: "/dashboard/blogs",
    icon: "cil-book",
    // role: [environment.role_admin, environment.role_user],
  },
  {
    divider: true,
  },
];
