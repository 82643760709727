import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../../services/auth/login.service";
import { GlobalService } from "../../services/global.service";
import { navItems } from "../../_nav";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent {
  constructor(
    private loginService: LoginService,
    private globalService: GlobalService,
    private router: Router
  ) {}

  public sidebarMinimized = false;
  public navItems = navItems;

  id;
  details;
  user_role;
  sessionId;

  ngOnInit(): void {
    this.user_role = sessionStorage.getItem("user_role");
    this.id = sessionStorage.getItem("user_id");
    this.sessionId = sessionStorage.getItem("session_id");

    this.getUserDetails();
  }

  getUserDetails() {
    let user = {
      user_id: this.id,
    };
    this.loginService.loginUserDetail(user).subscribe((response: any) => {
      this.details = response.data;
    });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    let logoutData = {
      user_id: this.id,
      session_id: this.sessionId,
      activity: "2",
      date: this.globalService.getDate(),
      time: this.globalService.getTime(),
    };
    this.loginService.addLogoutLog(logoutData).subscribe(() => {});
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("user_role");
    sessionStorage.removeItem("session_id");
    this.router.navigateByUrl("/auth/login");
  }
}
