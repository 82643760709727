import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CanActivateDashboardService {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const userId = sessionStorage.getItem("user_id");
    // const role = sessionStorage.getItem("role");    
    if (userId) {
      return true;
    }
    Swal.fire({
      icon: "error",
      title: "Invalid Request!",
      text: "Please login!",
      showConfirmButton: false,
      timer: 1500,
    });
    this.router.navigateByUrl("auth/login");
    return false;
  }
}