import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CanLoadLoginService {
  constructor(private router: Router) {}

  canLoad(): boolean {
    const userId = sessionStorage.getItem("token");
    if (!userId) {
      return true;
    }
    this.router.navigateByUrl("dashboard/mainDashboard");
    return false;
  }
}
